import * as React from 'react'
import { useStoryblok } from 'hooks'

export interface NotFoundPageProps {
  location: Location
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ location }) => {
  const story = useStoryblok(null, location)

  let content = <h1>Not found</h1>
  if (story && story.content) content = <Page blok={story.content} />

  return <div>{content}</div>
}

export default NotFoundPage
